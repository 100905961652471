import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.array.sort";
import React, { useState } from "react";
import Icon, { Flag } from "../../../packages/Icon/src";
import Div from "../../../packages/Div/src";
import Flex from "../../../packages/Flex/src";
import { P, Span } from "../../../packages/Typography/src";
import Select from "../../../packages/Select/src";
import Textfield from "../../../packages/Textfield/src";
import Divider from "../../../packages/Divider/src";
var colors = ["black", "green", "red", "yellow", "white", "grey"];

var parseSprite = function parseSprite(sprite) {
  return sprite.match(/<symbol id="\w+"/gm).map(function (d) {
    return d.slice(12, -1);
  }).sort(function (a, b) {
    return a.localeCompare(b);
  });
};

var Listing = function Listing(_ref) {
  var items = _ref.items,
      type = _ref.type;
  return React.createElement(Flex, null, items.map(function (item) {
    return React.createElement(Div, {
      key: item,
      width: [1, 1 / 2, 1 / 3, 1 / 4]
    }, React.createElement("div", {
      style: {
        padding: "3rem 1rem",
        textAlign: "center",
        border: "1px solid #eee"
      }
    }, type === "icon" ? React.createElement(Icon, {
      name: item
    }) : React.createElement(Flag, {
      name: item
    }), React.createElement("div", {
      style: {
        paddingTop: "1rem",
        color: "#666"
      }
    }, item)));
  }));
};

var IconsCheckPreview = function IconsCheckPreview() {
  var _useState = useState(null),
      spriteSheet = _useState[0],
      setSpriteSheet = _useState[1];

  var _useState2 = useState("icon"),
      iconsType = _useState2[0],
      setType = _useState2[1];

  var _useState3 = useState(null),
      icon = _useState3[0],
      setIcon = _useState3[1];

  var _useState4 = useState("black"),
      iconColor = _useState4[0],
      setIconColor = _useState4[1];

  var _useState5 = useState("black"),
      iconFill = _useState5[0],
      setIconFill = _useState5[1];

  var _useState6 = useState("black"),
      iconStroke = _useState6[0],
      setIconStroke = _useState6[1];

  var handleUploadFile = function handleUploadFile(event) {
    if (!window.FileReader) return;
    var reader = new FileReader();

    reader.onload = function (e) {
      if (e.target.readyState != 2 || e.target.error) return;
      var spriteSheet = parseSprite(e.target.result);

      if (spriteSheet.length > 0) {
        setSpriteSheet(spriteSheet);
        setIcon(spriteSheet[0]);
      }
    };

    reader.readAsText(event.target.files[0]);
  };

  var handleSelectType = function handleSelectType(_ref2) {
    var target = _ref2.target;
    setType(target.value);
  };

  var handleIconForPreview = function handleIconForPreview(_ref3) {
    var target = _ref3.target;
    setIcon(target.value);
  };

  var handleIconColor = function handleIconColor(_ref4) {
    var target = _ref4.target;
    setIconColor(target.value);
  };

  var handleIconFill = function handleIconFill(_ref5) {
    var target = _ref5.target;
    setIconFill(target.value);
  };

  var handleIconStroke = function handleIconStroke(_ref6) {
    var target = _ref6.target;
    setIconStroke(target.value);
  };

  return React.createElement(React.Fragment, null, React.createElement(Div, {
    display: "flex",
    style: {
      justifyContent: "space-between"
    }
  }, React.createElement(Textfield, {
    width: "50%",
    type: "file",
    name: "myFile",
    onChange: handleUploadFile
  }), React.createElement(Div, null, React.createElement(Span, null, "Icon type:"), React.createElement(Select, {
    ml: "2",
    value: iconsType,
    onChange: handleSelectType,
    options: [{
      value: "icon",
      label: "Icon"
    }, {
      value: "flag",
      label: "Flag"
    }]
  }))), React.createElement(Divider, {
    my: "2rem",
    borderWidth: 2,
    color: "grey.100"
  }), spriteSheet && React.createElement(Div, {
    display: "flex"
  }, React.createElement(Div, {
    mt: "4",
    width: [1, 2 / 3]
  }, React.createElement(Icon, {
    name: icon,
    type: iconsType,
    color: iconColor,
    fill: iconFill,
    stroke: iconStroke
  })), React.createElement(Div, {
    width: [1, 1 / 3]
  }, React.createElement(Div, null, React.createElement(Span, null, "Name:"), React.createElement(Select, {
    ml: "2",
    width: "100%",
    value: icon,
    onChange: handleIconForPreview,
    options: spriteSheet.map(function (i) {
      return {
        value: i,
        label: i
      };
    })
  })), React.createElement(Div, {
    mt: "2"
  }, React.createElement(Span, null, "Color:"), React.createElement(Select, {
    ml: "2",
    width: "100%",
    value: iconColor,
    onChange: handleIconColor,
    options: colors.map(function (i) {
      return {
        value: i,
        label: i
      };
    })
  })), React.createElement(Div, null, React.createElement(Span, null, "Fill:"), React.createElement(Select, {
    ml: "2",
    width: "100%",
    value: iconFill,
    onChange: handleIconFill,
    options: colors.map(function (i) {
      return {
        value: i,
        label: i
      };
    })
  })), React.createElement(Div, {
    mt: "2"
  }, React.createElement(Span, null, "Stroke:"), React.createElement(Select, {
    ml: "2",
    width: "100%",
    value: iconStroke,
    onChange: handleIconStroke,
    options: colors.map(function (i) {
      return {
        value: i,
        label: i
      };
    })
  })))), React.createElement(Div, null, spriteSheet ? React.createElement(React.Fragment, null, React.createElement(P, null, "Spritesheet icons preview"), React.createElement(Listing, {
    items: spriteSheet,
    type: iconsType
  })) : React.createElement(P, null, "Choose and upload spritesheet")));
};

export default IconsCheckPreview;